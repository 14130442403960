var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showMenu)?_c('v-row',{staticClass:"hidden-md-and-up"},[_c('v-col',{staticClass:"text-right"},[_c('v-menu',{attrs:{"offset-y":"","offset-x":"","rounded":"","nudge-left":"100%","max-width":"100%","max-height":"100%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, menu)),[_c('v-icon',{staticClass:"mx-2",attrs:{"medium":""}},[_vm._v("menu")])],1)]}}],null,false,254044017)},[_vm._v(" "),_c('v-list',[_c('v-subheader',[_c('v-icon',{staticClass:"mx-2",attrs:{"medium":""}},[_vm._v("fas fa-user")]),_vm._v("\n            "+_vm._s(this.$store.state.user.personalInfo.email)+"\n          ")],1),_vm._v(" "),(_vm.$productName == _vm.PRODUCT_CODES.avcwise)?_c('v-list-item',{on:{"click":function($event){return _vm.openContactUs()}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{staticClass:"mx-2",attrs:{"medium":""}},[_vm._v("fa-solid fa-envelope-open-text")])],1),_vm._v(" "),_c('v-list-item-content',[_vm._v("Contact Us")])],1):_vm._e(),_vm._v(" "),(_vm.$productName !== _vm.PRODUCT_CODES.avcwise)?_c('v-list-item',{on:{"click":function($event){return _vm.redirectToAccountSettings()}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{staticClass:"mx-2 icon-default-size",attrs:{"medium":""}},[_vm._v("fas fa-cog")])],1),_vm._v(" "),_c('v-list-item-content',[_vm._v("Settings")])],1):_vm._e(),_vm._v(" "),(
              _vm.$productName !== _vm.PRODUCT_CODES.avcwise &&
                _vm.$productName !== _vm.PRODUCT_CODES.firefighter &&
                _vm.$productName !== _vm.PRODUCT_CODES.psh
            )?_c('v-list-item',{on:{"click":function($event){return _vm.redirectToDocuments()}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{staticClass:"mx-2 icon-default-size",attrs:{"medium":"","color":_vm.currentPage === 'documents' ? 'primary' : ''}},[_vm._v("fas fa-file-alt")])],1),_vm._v(" "),_c('v-list-item-content',[_vm._v("Documents")])],1):_vm._e(),_vm._v(" "),(_vm.$productName !== _vm.PRODUCT_CODES.avcwise)?_c('v-list-item',{on:{"click":function($event){_vm.shownoti = true;
              _vm.$store.state.notifications.newNotification &&
                _vm.readNotification();}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{staticClass:"mx-2 icon-default-size",attrs:{"medium":""}},[_vm._v("fas fa-bell")]),_vm._v(" "),(_vm.$store.state.notifications.newNotification)?_c('i',{staticClass:"fas notif-red-bell mobile-red-dot"},[_vm._v(_vm._s(_vm.$store.state.notifications.newNotification))]):_vm._e()],1),_vm._v(" "),_c('v-list-item-content',[_vm._v("Notifications")])],1):_vm._e(),_vm._v(" "),(_vm.$productName !== _vm.PRODUCT_CODES.avcwise)?_c('v-list-item',{on:{"click":function($event){_vm.$router.push('/help');
              _vm.callGAEvent(_vm.$gtag, {
                eventName: 'help_btn',
                category: 'menu_click'
              });}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{staticClass:"mx-2 icon-default-size",attrs:{"medium":""}},[_vm._v("fa fa-question-circle")])],1),_vm._v(" "),_c('v-list-item-content',[_vm._v("Help / FAQ's")])],1):_vm._e(),_vm._v(" "),_c('v-list-item',{on:{"click":function($event){return _vm.logoutUser()}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{staticClass:"mx-2 icon-default-size",attrs:{"medium":""}},[_vm._v("fas fa-power-off")])],1),_vm._v(" "),_c('v-list-item-content',[_vm._v("Logout")])],1)],1)],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.shownoti)?_c('v-navigation-drawer',{staticClass:"hidden-md-and-up",staticStyle:{"position":"fixed","top":"0","right":"0","overflow-y":"scroll"},attrs:{"right":"","temporary":"","width":"430px"},model:{value:(_vm.shownoti),callback:function ($$v) {_vm.shownoti=$$v},expression:"shownoti"}},[_c('v-list-item',{staticClass:"pl-4 bold-600"},[_c('v-list-item-content',{staticClass:"py-6"},[_c('v-list-item-title',{staticClass:"text-xl"},[_vm._v("\n          Notifications\n        ")])],1),_vm._v(" "),_c('v-list-item-icon',{staticClass:"my-auto"},[_c('v-icon',{staticClass:"mx-2",attrs:{"medium":""},on:{"click":function($event){_vm.shownoti = false}}},[_vm._v("mdi-close-circle")])],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),(
        this.$store.state.notifications.notifications &&
          this.$store.state.notifications.notifications.length > 0
      )?_c('v-list',_vm._l((this.$store.state.notifications.notifications),function(item,index){return _c('div',{key:index},[_c('v-list-item',{staticClass:"notif-item",class:item.isRead ? '' : 'new-notif',attrs:{"id":item.id}},[_c('v-list-item-content',[_vm._v(_vm._s(item.text))])],1),_vm._v(" "),_c('v-divider')],1)}),0):_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_vm._v("You don't have any notifications yet")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }