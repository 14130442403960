<template>
  <div>
    <div
      v-if="isAvcwiseUser()"
      id="calendly-widget"
      class="custom-calendly-badge-widget d-none"
    >
      <div
        class="hidden-sm-and-down custom-calendly-badge-content"
        @click="clickCalendlyBtn"
      >
        Book pension guidance session
      </div>
      <div
        @click="clickCalendlyBtn"
        class="hidden-md-and-up custom-calendly-badge-icon elevation-24"
      >
        <img src="../../assets/AvcWise/customer-suppport.jpeg" />
      </div>
    </div>
    <ContactUs v-else />
  </div>
</template>

<script>
import { openCalendlyWidget, callGAEvent } from '~/plugins/utils';
import { PRODUCT_CODES } from '~/constants';
export default {
  components: {
    ContactUs: () => import('~/components/Footer/ContactUs.vue')
  },
  data() {
    return {
      showTNC: false
    };
  },
  methods: {
    openTNCdialog() {
      this.showTNC = true;
    },
    clickCalendlyBtn() {
      callGAEvent(this.$gtag, {
        eventName: 'pension_guidance_btn',
        category: 'btn_click'
      });
      openCalendlyWidget();
    },
    isAvcwiseUser() {
      return this.$productName === PRODUCT_CODES.avcwise;
    }
  }
};
</script>
<style lang="scss" scoped>
.footer-border {
  width: 50rem;
  height: 1px;
  opacity: 0.1;
  margin: 1rem auto;
  border: solid 1px #0e0c23;
  background-color: #ffffff;
}

.footer-divider {
  height: 1px;
  opacity: 0.5;
  margin: 1rem auto;
  border: solid 1px #ffffff;
  background-color: #ffffff;
}

.footer-credits {
  line-height: 2;
  font-size: $font-small;
  letter-spacing: normal;
}
.Cookie {
  max-width: 70%;
  margin-left: 15%;
  margin-right: 15%;
}

.custom-calendly-badge-content {
  background-color: $primary_blue;
}

@media only screen and (max-width: 767px) {
  .Cookie {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    top: 0 !important;
    bottom: auto !important;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
