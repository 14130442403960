<template>
  <v-snackbar v-model="show" :color="color" bottom :timeout="timeout">
    {{ message }}
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      message: '',
      color: 'red',
      timeout: 5000
    };
  },
  created() {
    this.$store.watch(
      (state) => state.snackbar.snack,
      () => {
        const msg = this.$store.state.snackbar.snack;
        if (msg !== '') {
          this.show = true;
          this.message = this.$store.state.snackbar.snack;
          setTimeout(() => {
            this.$store.commit('setSnack', '');
          }, 5000);
        }
      }
    );
  }
};
</script>
