<template>
  <header>
    <client-only>
      <div class="lighten-5 my-3" style="margin-left:8%;margin-right:8%">
        <v-row>
          <v-col :md="2" :sm="2" class="my-auto pa-0">
            <nuxt-link to="/"
              ><img
                style="height: 60px;"
                alt="logo"
                src="../../assets/AW-logos/logo@2x.png"
            /></nuxt-link>
          </v-col>
          <v-col :md="8" :xs="0" :sm="8" class="hidden-md-and-up my-auto -mr-8">
            <DashboardMidTabsMobile
              :current-page="currentPage"
              :handle-next-step-click="handleNextStepClick"
            />
          </v-col>
          <v-col :md="8" :xs="0" :sm="8" class="hidden-sm-and-down my-auto">
            <DashboardMidTabs
              :current-page="currentPage"
              :handle-next-step-click="handleNextStepClick"
            />
          </v-col>
          <v-col :md="2" :xs="0" :sm="2" class="my-auto">
            <component
              :is="menuOptionsComponent"
              :current-page="currentPage"
              :redirect-to-account-settings="redirectToAccountSettings"
              :redirect-to-documents="redirectToDocuments"
              :logout-user="logoutUser"
              :read-notification="readNotification"
              :isOnboarding="false"
            />

            <component
              :is="menuOptionsMobileComponent"
              :show-menu="showMenu"
              :current-page="currentPage"
              :redirect-to-account-settings="redirectToAccountSettings"
              :redirect-to-documents="redirectToDocuments"
              :logout-user="logoutUser"
              :read-notification="readNotification"
              :isOnboarding="false"
            />
          </v-col>
        </v-row>
      </div>
    </client-only>
  </header>
</template>
<script>
import { getRetirementAge, logOut, callGAEvent } from '~/plugins/utils';
import { readUserNotification } from '~/plugins/apiFunctions';
import { PRODUCT_CODES } from '~/constants';
import DashboardMidTabs from '~/components/Header/DashboardMidTabs.vue';
import DashboardMidTabsMobile from '~/components/Header/MobileView/DashboardMidTabs.vue';
import MenuOptionsMobile from '~/components/Header/MobileView/MenuOptions.vue';

export default {
  components: {
    DashboardMidTabs,
    DashboardMidTabsMobile,
    MenuOptionsMobile
  },
  data() {
    return {
      currentPage: '',
      showMenu: false,
      shownoti: false
    };
  },
  watch: {
    $route() {
      this.rerenderPage();
    }
  },
  computed: {
    menuOptionsComponent() {
      const menuOption =
        this.$productName == PRODUCT_CODES.avcwise
          ? 'AvcMenuOptions'
          : 'MenuOptions';

      return () => import(`~/components/Header/${menuOption}.vue`);
    },
    menuOptionsMobileComponent() {
      const menuOption =
        this.$productName == PRODUCT_CODES.avcwise
          ? 'AvcMenuOptions'
          : 'MenuOptions';

      return () => import(`~/components/Header/MobileView/${menuOption}.vue`);
    }
  },
  mounted() {
    this.rerenderPage();
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    callGAEvent,
    resizeHandler() {
      if (window.innerWidth >= 1264) {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }

      if (window.innerHeight > 768) {
        this.shownoti = false;
      }
    },
    rerenderPage() {
      const newSelection = this.$router.history.current.name.split('-')[
        this.$router.history.current.name.split('-').length - 1
      ];
      if (newSelection === 'nextsteps') {
        this.currentPage = 'nextsteps';
      } else if (!this.isAvcWiseProduct() && newSelection === 'landing') {
        this.currentPage = 'landing';
      } else if (newSelection === 'documents') {
        this.currentPage = 'documents';
      } else if (!this.isAvcWiseProduct() && newSelection === 'retirement') {
        this.currentPage = 'retirement';
      } else if (newSelection === 'combine') {
        this.currentPage = 'combine';
      } else if (
        this.isAvcWiseProduct() &&
        (newSelection === 'landing' || newSelection === 'shortlist-funds')
      ) {
        this.currentPage = 'funds';
      } else if (this.isAvcWiseProduct() && newSelection === 'retirement') {
        this.currentPage = 'retirement';
      } else if (this.isAvcWiseProduct() && newSelection === 'fund-selection') {
        this.currentPage = 'selection';
      } else {
        this.currentPage = 'settings';
      }
    },
    async redirectToDocuments() {
      callGAEvent(this.$gtag, {
        eventName: 'documents_btn',
        category: 'btn_click'
      });

      await this.$router.push({
        path: '/dashboard/documents',
        name: 'dashboard-documents'
      });
      // this.$store.commit('updateCurrentTab', 'documents');
      this.rerenderPage();
      // this.rerenderPage('documents');
    },
    redirectToEmploymentInfo() {
      this.$router.push({
        path: '/onboarding/employment-info',
        name: 'onboarding-employment-info'
      });
    },
    async logoutUser() {
      const that = this;
      await logOut(that);
    },
    async redirectToAccountSettings() {
      await this.$router.push({
        path: '/dashboard/settings',
        name: 'dashboard-settings'
      });
      // this.$store.commit('updateCurrentTab', 'settings');
      this.rerenderPage();
      // this.rerenderPage('settings');
    },
    getName() {
      if (
        this.$store.state.user.personalInfo.firstName.length +
          this.$store.state.user.personalInfo.lastName.length >
        20
      ) {
        return (
          `${this.$store.state.user.personalInfo.firstName} ${this.$store.state.user.personalInfo.lastName}`.substring(
            0,
            20
          ) + '..'
        );
      } else {
        return `${this.$store.state.user.personalInfo.firstName} ${this.$store.state.user.personalInfo.lastName}`;
      }
    },
    async readNotification(id) {
      await readUserNotification(id);
      this.$store.commit('readNotification', id);
    },
    handleNextStepClick() {
      this.$nuxt.$emit('toNextSteps', true);
      const { currAge } = getRetirementAge(
        this.$store.state.user.personalInfo.ur_dob
      );
      let scoreCount = 0;
      try {
        scoreCount = Object.keys(this.$store.state.user.pensionStatus).filter(
          (uuid) =>
            !this.$store.state.user.pensionStatus[uuid].deletedByUser &&
            !this.$store.state.user.pensionStatus[uuid].score
        ).length;
      } catch (error) {}
    },
    isAvcWiseProduct() {
      return this.$productName === PRODUCT_CODES.avcwise;
    }
  }
};
</script>
<style lang="scss">
.navbar.vue-fixed-header--isFixed {
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
}

.nav {
  background-color: #333;
  overflow: hidden;
  color: #000;
  z-index: 0;
}

.nav-btn {
  border-radius: 23.5px;
  border: solid 1px #ffffff;
  color: #ffffff;
}

.mobile-notif-menu {
  margin: 14px;
}
.notif-red-bell {
  font-size: $font-small;
  color: white;
  background: red;
  border-radius: 50%;
  padding: 2px 4px;
  position: absolute;
  right: -8%;
  bottom: 15px;
}
.notif-item {
  cursor: pointer;
}
.new-notif {
  background: #ff00001c;
}
.mobile-red-dot {
  bottom: 30px !important;
  left: 22% !important;
  right: unset;
}

.v-card__title {
  word-break: break-word !important;
}

.icon-default-size {
  width: 24px;
}
</style>
