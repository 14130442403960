var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('v-menu',{attrs:{"transition":"slide-y transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-auto",attrs:{"color":"primary","id":"header-tab-btn","dark":"","text":""}},'v-btn',attrs,false),on),[_vm._v("\n        "+_vm._s(_vm.getCurrTab())+"\n        "),_c('v-icon',{staticClass:"pl-4",attrs:{"right":""}},[_vm._v("fas fa-angle-down")])],1)]}}])},[_vm._v(" "),_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},[(!_vm.isAvcWiseUser())?_c('v-list-item',{key:"landing",attrs:{"to":"/dashboard/landing"},on:{"click":function($event){return _vm.callGAEvent(_vm.$gtag, {
              eventName: 'dashboard_tab',
              category: 'btn_click'
            })}}},[_c('v-list-item-title',{staticClass:"text-center"},[_vm._v("Dashboard")])],1):_vm._e(),_vm._v(" "),(_vm.hasPensionbeeAccess())?_c('v-list-item',{key:"combine",attrs:{"to":"/dashboard/combine"},on:{"click":function($event){return _vm.callGAEvent(_vm.$gtag, {
              eventName: 'combine_tab',
              category: 'btn_click'
            })}}},[_c('v-list-item-title',{staticClass:"text-center"},[_vm._v("Combine")])],1):_vm._e(),_vm._v(" "),(_vm.productName !== _vm.PRODUCT_CODES.pensionbee && !_vm.isAvcWiseUser())?_c('v-list-item',{key:"retirement",attrs:{"to":"/dashboard/retirement"}},[_c('v-list-item-title',{staticClass:"text-center"},[_vm._v("Retirement")])],1):_vm._e(),_vm._v(" "),(
            _vm.allowDashboard &&
              _vm.productName !== _vm.PRODUCT_CODES.pensionbee &&
              !_vm.isAvcWiseUser()
          )?_c('v-list-item',{key:"nextsteps",attrs:{"to":"/dashboard/nextsteps"},on:{"click":_vm.handleNextStepClick}},[_c('v-list-item-title',{staticClass:"text-center"},[_vm._v("Next Steps")])],1):_vm._e(),_vm._v(" "),(_vm.isAvcWiseUser())?_c('v-list-item',{key:"selection",attrs:{"to":"/onboarding/fund-selection"}},[_c('v-list-item-title',{staticClass:"text-center"},[_vm._v("Selection")])],1):_vm._e(),_vm._v(" "),(_vm.isAvcWiseUser())?_c('v-list-item',{key:"landing",attrs:{"to":"/dashboard/landing"}},[_c('v-list-item-title',{staticClass:"text-center"},[_vm._v("Funds")])],1):_vm._e(),_vm._v(" "),(_vm.isAvcWiseUser())?_c('v-list-item',{key:"retirement",attrs:{"id":"v-step-6-mb","to":"/dashboard/retirement"}},[_c('v-list-item-title',{staticClass:"text-center"},[_vm._v("Retirement")])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }