<template>
  <div>
    <v-tabs
      id="tour-step-header"
      v-model="currPage"
      :value="currentPage"
      background-color="transparent"
      centered
    >
      <v-tab
        v-if="!isAvcWiseUser()"
        key="landing"
        to="/dashboard/landing"
        @click="
          callGAEvent($gtag, {
            eventName: 'dashboard_tab',
            category: 'btn_click'
          })
        "
      >
        Dashboard
      </v-tab>
      <v-tab
        v-if="isAvcWiseUser()"
        key="selection"
        to="/onboarding/fund-selection"
        @click="
          callGAEvent($gtag, {
            eventName: 'dashboard_tab',
            category: 'btn_click'
          })
        "
      >
        Selection
      </v-tab>
      <v-tab
        v-if="isAvcWiseUser()"
        key="landing"
        to="/dashboard/landing"
        @click="
          callGAEvent($gtag, {
            eventName: 'dashboard_tab',
            category: 'btn_click'
          })
        "
      >
        Funds
      </v-tab>
      <v-tab
        v-if="isAvcWiseUser()"
        id="v-step-6"
        key="retirement"
        to="/dashboard/retirement"
        @click="
          callGAEvent($gtag, {
            eventName: 'dashboard_tab',
            category: 'btn_click'
          })
        "
      >
        Retirement
      </v-tab>
      <v-tab
        v-if="hasPensionbeeAccess()"
        key="combine"
        to="/dashboard/combine"
        @click="
          callGAEvent($gtag, {
            eventName: 'combine_tab',
            category: 'btn_click'
          })
        "
      >
        Combine
      </v-tab>
      <v-tab
        v-if="productName !== PRODUCT_CODES.pensionbee && !isAvcWiseUser()"
        key="retirement"
        to="/dashboard/retirement"
      >
        Retirement
      </v-tab>
      <v-tab
        v-if="
          allowDashboard &&
            productName !== PRODUCT_CODES.pensionbee &&
            !isAvcWiseUser()
        "
        key="nextsteps"
        to="/dashboard/nextsteps"
        @click="handleNextStepClick"
      >
        Next Steps
      </v-tab>
    </v-tabs>
  </div>
</template>
<script>
import { allowDashboard, PRODUCT_CODES } from '~/constants/';
import { callGAEvent } from '~/plugins/utils';
export default {
  props: ['handleNextStepClick', 'currentPage'],
  data() {
    return {
      allowDashboard,
      currPage: this.currentPage,
      productName: this.$productName,
      PRODUCT_CODES
    };
  },
  methods: {
    callGAEvent,
    hasPensionbeeAccess() {
      return this.$store.state.user.pensionbeeuser;
    },
    isAvcWiseUser() {
      return this.$productName === PRODUCT_CODES.avcwise;
    }
  }
};
</script>
