<template>
  <div class="m-auto w-4/5 block justify-between section">
    <client-only>
      <cookie-law theme="royal" />
    </client-only>
    <div
      v-show="
        $store.state.user && $store.state.user.state.loggedIn && isAvcwiseUser()
      "
      class="text-center footer-credits bold-600 font-regular black--text mb-4"
    >
      Not Sure What To Do? Talk To Our Pension Experts.
      <a @click="clickCalendlyBtn" class="link-text">Click here</a>
    </div>
    <div class="text-center footer-credits py-2">
      © copyright {{ getCurrYear() }} AgeWage Ltd. Company registration:
      11429498. Information Commissioner's office registration: ZA540667.<br />
      <span v-show="isAvcwiseUser()">
        If you wish to contact us please
        <a class="link-text" @click="showContactForm = true">click here</a>.
      </span>
      If you wish to register a complaint, please write to
      <a href="mailto:complaints@agewage.com" class="link-text"
        >complaints@agewage.com</a
      >
      <br />
      <a @click="openTNCdialog" class="link-text">
        Terms and Conditions
      </a>
    </div>
    <div
      v-if="isAvcwiseUser()"
      id="calendly-widget"
      class="custom-calendly-badge-widget d-none"
    >
      <div
        class="hidden-sm-and-down custom-calendly-badge-content"
        @click="clickCalendlyBtn"
      >
        Book pension guidance session
      </div>
      <div
        class="hidden-md-and-up custom-calendly-badge-icon elevation-24"
        @click="clickCalendlyBtn"
      >
        <img src="../../assets/AvcWise/customer-suppport.jpeg" />
      </div>
    </div>
    <v-dialog v-model="showTNC" width="600px" scrollable>
      <v-card>
        <TNC />
        <v-divider></v-divider>
        <v-card-actions class="mx-auto">
          <v-btn color="blue darken-1" text @click="showTNC = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showContactForm" max-width="600px">
      <ContactUsDialog :close-contact-dialog="closeContactForm" />
    </v-dialog>
    <v-snackbar
      v-model="snackbar.model"
      :color="snackbar.color ? snackbar.color : ''"
      bottom
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import { getCurrYear, openCalendlyWidget, callGAEvent } from '~/plugins/utils';
import { PRODUCT_CODES } from '~/constants';
export default {
  components: {
    TNC: () => import('~/components/Footer/TNC.vue'),
    ContactUsDialog: () => import('~/components/ContactUsDialog.vue')
  },
  data() {
    return {
      showTNC: false,
      showContactForm: false,
      snackbar: {
        model: false,
        text: '',
        color: 'red',
        timeout: 2000
      }
    };
  },
  created() {
    const that = this;
    this.$nuxt.$on('loadingContactDialog', (data) => {
      if (data) {
        that.showContactForm = false;
        this.snackbar.model = true;
        this.snackbar.text = 'Email sent successfully.';
        this.snackbar.color = 'green';
        this.snackbar.timeout = 5000;
      } else {
        this.snackbar.model = true;
        this.snackbar.text = 'Error sending contact email.';
        this.snackbar.color = 'red';
        this.snackbar.timeout = 5000;
      }
    });
  },
  methods: {
    getCurrYear,
    openTNCdialog() {
      callGAEvent(this.$gtag, {
        eventName: 'terms_and_conditions_link',
        category: 'btn_click',
        label: 'page_footer'
      });
      this.showTNC = true;
    },
    clickCalendlyBtn() {
      callGAEvent(this.$gtag, {
        eventName: 'pension_guidance_btn',
        category: 'btn_click'
      });
      openCalendlyWidget();
    },
    closeContactForm() {
      this.showContactForm = false;
    },
    isAvcwiseUser() {
      return this.$productName === PRODUCT_CODES.avcwise;
    }
  }
};
</script>

<style scoped lang="scss">
.footer-border {
  width: 50rem;
  height: 1px;
  opacity: 0.1;
  margin: 1rem auto;
  border: solid 1px #0e0c23;
  background-color: #ffffff;
}

.section {
  position: relative;
}

.footer-credits {
  line-height: 2;
  font-size: $font-small;
  letter-spacing: normal;
  color: rgba(14, 12, 35, 0.5);
}
.grey-color {
  color: rgba(14, 12, 35, 0.5);
}

.custom-calendly-badge-content {
  background-color: $primary_blue;
}

@media only screen and (max-width: 767px) {
  .Cookie {
    top: 0 !important;
    bottom: auto !important;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
