var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{attrs:{"id":"tour-step-header","value":_vm.currentPage,"background-color":"transparent","centered":""},model:{value:(_vm.currPage),callback:function ($$v) {_vm.currPage=$$v},expression:"currPage"}},[(!_vm.isAvcWiseUser())?_c('v-tab',{key:"landing",attrs:{"to":"/dashboard/landing"},on:{"click":function($event){return _vm.callGAEvent(_vm.$gtag, {
          eventName: 'dashboard_tab',
          category: 'btn_click'
        })}}},[_vm._v("\n      Dashboard\n    ")]):_vm._e(),_vm._v(" "),(_vm.isAvcWiseUser())?_c('v-tab',{key:"selection",attrs:{"to":"/onboarding/fund-selection"},on:{"click":function($event){return _vm.callGAEvent(_vm.$gtag, {
          eventName: 'dashboard_tab',
          category: 'btn_click'
        })}}},[_vm._v("\n      Selection\n    ")]):_vm._e(),_vm._v(" "),(_vm.isAvcWiseUser())?_c('v-tab',{key:"landing",attrs:{"to":"/dashboard/landing"},on:{"click":function($event){return _vm.callGAEvent(_vm.$gtag, {
          eventName: 'dashboard_tab',
          category: 'btn_click'
        })}}},[_vm._v("\n      Funds\n    ")]):_vm._e(),_vm._v(" "),(_vm.isAvcWiseUser())?_c('v-tab',{key:"retirement",attrs:{"id":"v-step-6","to":"/dashboard/retirement"},on:{"click":function($event){return _vm.callGAEvent(_vm.$gtag, {
          eventName: 'dashboard_tab',
          category: 'btn_click'
        })}}},[_vm._v("\n      Retirement\n    ")]):_vm._e(),_vm._v(" "),(_vm.hasPensionbeeAccess())?_c('v-tab',{key:"combine",attrs:{"to":"/dashboard/combine"},on:{"click":function($event){return _vm.callGAEvent(_vm.$gtag, {
          eventName: 'combine_tab',
          category: 'btn_click'
        })}}},[_vm._v("\n      Combine\n    ")]):_vm._e(),_vm._v(" "),(_vm.productName !== _vm.PRODUCT_CODES.pensionbee && !_vm.isAvcWiseUser())?_c('v-tab',{key:"retirement",attrs:{"to":"/dashboard/retirement"}},[_vm._v("\n      Retirement\n    ")]):_vm._e(),_vm._v(" "),(
        _vm.allowDashboard &&
          _vm.productName !== _vm.PRODUCT_CODES.pensionbee &&
          !_vm.isAvcWiseUser()
      )?_c('v-tab',{key:"nextsteps",attrs:{"to":"/dashboard/nextsteps"},on:{"click":_vm.handleNextStepClick}},[_vm._v("\n      Next Steps\n    ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }