<template>
  <div class="d-flex">
    <v-menu transition="slide-y transition" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-auto"
          color="primary"
          id="header-tab-btn"
          dark
          text
          v-bind="attrs"
          v-on="on"
        >
          {{ getCurrTab() }}
          <v-icon class="pl-4" right>fas fa-angle-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group color="primary">
          <v-list-item
            v-if="!isAvcWiseUser()"
            key="landing"
            to="/dashboard/landing"
            @click="
              callGAEvent($gtag, {
                eventName: 'dashboard_tab',
                category: 'btn_click'
              })
            "
          >
            <v-list-item-title class="text-center">Dashboard</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="hasPensionbeeAccess()"
            key="combine"
            to="/dashboard/combine"
            @click="
              callGAEvent($gtag, {
                eventName: 'combine_tab',
                category: 'btn_click'
              })
            "
          >
            <v-list-item-title class="text-center">Combine</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="productName !== PRODUCT_CODES.pensionbee && !isAvcWiseUser()"
            key="retirement"
            to="/dashboard/retirement"
          >
            <v-list-item-title class="text-center"
              >Retirement</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            v-if="
              allowDashboard &&
                productName !== PRODUCT_CODES.pensionbee &&
                !isAvcWiseUser()
            "
            key="nextsteps"
            to="/dashboard/nextsteps"
            @click="handleNextStepClick"
          >
            <v-list-item-title class="text-center"
              >Next Steps</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            v-if="isAvcWiseUser()"
            key="selection"
            to="/onboarding/fund-selection"
          >
            <v-list-item-title class="text-center">Selection</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isAvcWiseUser()"
            key="landing"
            to="/dashboard/landing"
          >
            <v-list-item-title class="text-center">Funds</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isAvcWiseUser()"
            id="v-step-6-mb"
            key="retirement"
            to="/dashboard/retirement"
          >
            <v-list-item-title class="text-center"
              >Retirement</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { allowDashboard, PRODUCT_CODES } from '~/constants/';
import { callGAEvent } from '~/plugins/utils';
export default {
  props: ['handleNextStepClick', 'currentPage'],
  data() {
    return {
      allowDashboard,
      productName: this.$productName,
      PRODUCT_CODES
    };
  },
  methods: {
    callGAEvent,
    getCurrTab() {
      if (this.currentPage === 'landing') {
        return 'Dashboard';
      }
      if (!this.isAvcWiseUser() && this.currentPage === 'retirement') {
        return 'Retirement';
      }
      if (this.currentPage === 'nextsteps') {
        return 'Next Steps';
      }
      if (this.currentPage === 'combine') {
        return 'Combine';
      }
      if (this.isAvcWiseUser() && this.currentPage === 'retirement') {
        return 'Retirement';
      }
      if (this.currentPage === 'funds') {
        return 'Funds';
      }
      if (this.currentPage === 'selection') {
        return 'Selection';
      }
      return 'Dashboard';
    },
    hasPensionbeeAccess() {
      return this.$store.state.user.pensionbeeuser;
    },
    isAvcWiseUser() {
      return this.$productName === PRODUCT_CODES.avcwise;
    }
  }
};
</script>
